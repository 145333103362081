// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// https://www.npmjs.com/package/slick-carousel
require('../../assets/library/slick-carousel/slick/slick.min.js');

// https://www.npmjs.com/package/@fancyapps/fancybox
require('@fancyapps/fancybox');

// https://github.com/bartholomej/material-scrollTop
require('material-scrolltop');
$('body').materialScrollTop();


// Scripts
require('./modules/sliders');
require('./modules/scripts');


// https://michalsnik.github.io/aos/
$(document).ready(function(){



  $('form.cart').on( 'click', 'span.plus, span.minus', function() {
        // Get current quantity values
        var qty = $( this ).closest( 'form.cart' ).find( '.qty' );
        var val   = parseFloat(qty.val());
        var max = parseFloat(qty.attr( 'max' ));
        var min = parseFloat(qty.attr( 'min' ));
        var step = parseFloat(qty.attr( 'step' ));
        
        // Change the value if plus or minus
        if ( $( this ).is( '.plus' ) ) {
         if ( max && ( max <= val ) ) {
          qty.val( max );
        } else {
          qty.val( val + step );
        }
      } else {
       if ( min && ( min >= val ) ) {
        qty.val( min );
      } else if ( val > 1 ) {
        qty.val( val - step );
      }
    }
  });


  var AOS = require('aos');
  AOS.init({duration:800,easing:'slide',once:true});
});


// jQuery(document).ready(function($) {
//     $(document.body).on('added_to_cart removed_from_cart', function() {
//         $.ajax({
//             url: wc_add_to_cart_params.ajax_url,
//             type: 'POST',
//             data: {
//                 action: 'woocommerce_get_cart_fragments'
//             },
//             success: function(fragments) {
//                 $.each(fragments, function(key, value) {
//                     $(key).replaceWith(value);
//                 });
//             }
//         });
//     });
// });












