console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);




document.addEventListener('DOMContentLoaded', function() {

  // #Header ,activar is-active-fixed al hacer scroll
  window.onscroll = function() {
    myFunction();
  };
  var header = document.getElementById("header");
  var space  = document.getElementById("js-body__header__space-fixed");
  function myFunction() {
    if (window.pageYOffset > 150) {
      header.classList.add("is-active-fixed");
      space.style.display = 'block'; 
    } else {
      header.classList.remove("is-active-fixed");
    }
  }

  function updateSpaceHeight() {
    space.style.height = header.offsetHeight + 'px';
  }

  window.onscroll = myFunction;
  window.onresize = updateSpaceHeight;


  space.style.height = header.offsetHeight + 'px';



  // Menu lateral
  $('#header .js-eventClick-open-child').click(function(){
    if ($('#body').hasClass('is-active-menu')){
      $(this).toggleClass('is-open');
      $(this).siblings().removeClass('is-open');
    }
  });
  $(document).keyup(function(e) {
    if ($('#body').hasClass('is-active-menu')) {
      if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
    }
  });
  $('#js-body__header__menu-mobile').click(function(){
    $('#js-header__menu-mobile').click();
  });

  $('#js-header__menu-mobile').click(function(){
    $('#body').toggleClass('is-active-menu');
    $(this).toggleClass('is-active');
    $('.header__layer').toggleClass('is-active');
    if (!$('.header__layer').hasClass('is-active')) {
      $('.header__layer').addClass('no-active');
    } else {
      $('.header__layer').removeClass('no-active');
    }
  });






  
});






